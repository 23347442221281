import { Button, useMediaQuery } from '@mui/material';
import { navigate } from 'gatsby';
import React, { useState } from 'react';

import * as classes from './howItWorkSection.module.scss';
import Memployer from './Memployer';
import Mexpert from './Mexpert';
import Employerfflow from '../UserFlow/Employerfflow';
import ExpertFlow from '../UserFlow/Expertflow';
import Typography from '../Typography/Typography';
import { MyTab, MyTabs } from '../Tabs';

export default function HowItWorkSection({ className, style }) {
  const matches = useMediaQuery('(min-width:720px)');
  const [activeTab, setActiveTab] = useState('employer');

  return (
    <div style={style} className={`${classes.hiwSection} ${className}`}>
      <Typography variant="h1" align="left">
      Hiring Process Map
      </Typography>
      <Typography variant="h3" align="center" maxWidth>
      Explore a streamlined hiring process enabled by our verified industry Experts, who recommend top candidates, or through direct employer job postings.
      </Typography>

      <MyTabs
        value={activeTab}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
        onChange={(e, v) => {
          setActiveTab(v);
        }}
        style={{ maxWidth: 480, marginTop: 48, marginBottom: 48 }}
      >
        <MyTab disabled={activeTab === 'employer'} value="employer" label="For employers" />
        <MyTab disabled={activeTab === 'expert'} value="experts" label="For Experts" />
      </MyTabs>
      {activeTab === 'employer' && (
        <div className={classes.howItWorkTabContent}>
          {matches ? <Employerfflow /> : <Memployer />}
        </div>
      )}
      {activeTab === 'experts' && (
        <div className={classes.howItWorkTabContent}>
          {matches ? <ExpertFlow /> : <Mexpert />}
        </div>
      )}
    </div>
  );
}
